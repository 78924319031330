import React from "react";

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Recipes from '../components/Recipes/Recipes';
import Footer from '../components/Footer/Footer';

// import BgDesktopSrc from '../images/hero-recipes-bg-desktop.jpg';
import BgDesktopSrc from '../images/hero-recipes-bg-desktop2.jpg';

import recipes from '../components/Recipes/config';

const RecipesPage = () => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
    >
      <Header />
      <Hero img={{ mobile: null, desktop: BgDesktopSrc }} title="Our&nbsp;Recipes" />
      <Recipes recipes={recipes} />
      <Footer />
    </div>
  );
};

export default RecipesPage;
